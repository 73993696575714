import React from 'react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

export default function LaunchDarkly({ children }) {
  const LDProvider = withLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID!,
    options: {
      bootstrap: 'localStorage',
      streaming: false
    }
  })(() => <>{children}</>)

  return <LDProvider />
}
