import { PaymentIssue } from 'src/types/OrderInfo'
export const getHasCancellation = (pendingChangesData: PaymentIssue[]) => {
  if (!Array.isArray(pendingChangesData) || pendingChangesData.length === 0) {
    return false
  }
  return pendingChangesData?.some(
    (item) =>
      item?.type === 'cancellation' &&
      (item?.reason?.type === 'cancellationAfterTrialPeriod' ||
        item?.reason?.type === 'cancellationWithinTrialPeriod' ||
        item?.reason?.type === 'cancellation')
  )
}
