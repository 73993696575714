import { OrderHelper } from '@/helpers/transformOrderResponseHelper'
import { Device, FulfillmentStatus, Order } from '@/types/OrderList'
import Item from './Item'
import { StyledProduct } from './styles'
import { GetProductsBySkuQuery } from 'src/__generated__/graphql'
import React from 'react'

export default function Product({
  order,
  orderFulfillmentData,
  productInfo,
  hasCancellation
}: {
  order: Order
  orderFulfillmentData: FulfillmentStatus
  productInfo: GetProductsBySkuQuery
  hasCancellation?: boolean
}) {
  const showItem = (orderItem: Device): boolean => {
    const fulfillmentOrder = OrderHelper.getFulfillmentObjById(
      orderFulfillmentData.fulFillments,
      orderItem.fulfillmentId
    )
    return Boolean(fulfillmentOrder?.orderItems[orderItem.sku])
  }

  return (
    <>
      {order?.devices
        ?.filter((orderItem) => showItem(orderItem))
        .map((orderItem, index) => (
          <StyledProduct
            addDivider={index > 0}
            key={index}
            data-testid={`product-item-${index}`}
          >
            <Item
              item={orderItem}
              orderFulfillmentData={orderFulfillmentData}
              productInfo={productInfo}
              hasCancellation={hasCancellation}
            />
          </StyledProduct>
        ))}
    </>
  )
}
