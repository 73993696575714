import axios from 'axios'
import getStratusToken from '@/helpers/getStratusToken'
import { getNavigatorLanguage } from '@/helpers/getLocation'
import {
  Fulfillment,
  FulfillmentResponse,
  Order,
  PaymentMethods,
  SubscriptionResponse
} from '@/types/OrderList'
import { OrderHelper } from '@/helpers/transformOrderResponseHelper'
import { forceLoginHandling } from '@/utils/forceLoginHandling'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'

export default class SubscriptionClient {
  constructor() {
    axios.defaults.params = {}
    axios.defaults.params['locale'] = getNavigatorLanguage()
  }

  async getOrders(): Promise<Order[]> {
    const url = `${process.env.BASE_URL_PROVIDER}/subscription-state/v1/subscriptions`

    const stratusToken = await getStratusToken()
    return axios
      .get<SubscriptionResponse>(url, {
        headers: {
          authorization: `Bearer ${stratusToken}`
        }
      })
      .then((response) =>
        response?.data?.contents
          ? OrderHelper.getOrderObj(response?.data.contents)
          : []
      )
      .catch((err) => {
        if (err?.response?.status === 403) {
          forceLoginHandling()
        }
        sendOpenTelemetryEvent(
          'Error when fetching orders from master bl ' + JSON.stringify(err)
        )
        throw err
      })
  }

  async getFulfillment(): Promise<Fulfillment[]> {
    const url = `${process.env.REACT_APP_DEVICE_FULFILLMENT_URL}/orders?size=100`
    const stratusToken = await getStratusToken()
    return axios
      .get<FulfillmentResponse>(url, {
        headers: {
          authorization: `Bearer ${stratusToken}`
        }
      })
      .then((response) => {
        return OrderHelper.getFulfillmentObj(response.data.content)
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          forceLoginHandling()
        }
        sendOpenTelemetryEvent(
          'Error when fetching fulfillment from device fullfilment api' +
            JSON.stringify(err)
        )
        throw err
      })
  }

  async getCommerceGeneralInfo(id: string) {
    const stratusToken = await getStratusToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }
    return await axios
      .get(
        `${process.env.BASE_URL_PROVIDER}/v3/commercemgtsvc/subscriptions/${id}?fields=pmReference`,
        {
          headers
        }
      )
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        sendOpenTelemetryEvent(
          `Error while fetching subscription info from commercemgtsvc api : ` +
            err?.message
        )
        throw err
      })
  }

  async getPaymentMethods(pmref: string): Promise<Partial<PaymentMethods>> {
    const stratusToken = await getStratusToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return await axios
      .get(`${process.env.BASE_URL_PROVIDER}/v1/payment-methods/${pmref}`, {
        headers
      })
      .then(({ data }) => {
        return data
      })
      .catch((error) => {
        sendOpenTelemetryEvent(
          `Error while fetching payment-methods info from payment-methods api : ` +
            error?.message
        )
        throw error
      })
  }

  async getPendingChanges(subscriptionId: string) {
    const stratusToken = await getStratusToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }
    return await axios
      .get(
        `${process.env.BASE_URL_PROVIDER}/subscription-state/v1/subscriptions/${subscriptionId}/pending-changes`,
        {
          headers
        }
      )
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        sendOpenTelemetryEvent(
          `Error while fetching subscription info from commercemgtsvc api : ` +
            err?.message
        )
        throw err
      })
  }
}
