import { useEffect, useState } from 'react'
import useSubscriptionClient from '../useSubscriptionClient'

export const usePendingChanges = (subId?: string) => {
  const client = useSubscriptionClient()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [info, setInfo] = useState([])

  useEffect(() => {
    const getPendingChangesBySubId = async () => {
      setIsLoading(true)
      try {
        const getData = await client.getPendingChanges(subId)
        setInfo(getData?.contents || [])
      } catch (err) {
        console.log(error, 'error')
        setError(true)
      }
      setIsLoading(false)
    }

    if (subId) {
      getPendingChangesBySubId()
    }
  }, [subId])
  return {
    info,
    error,
    isLoading
  }
}
