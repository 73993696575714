import IconWarning from '@veneer/core/dist/scripts/icons/icon_warning'
import Skeleton from 'react-loading-skeleton'
import React, { useState } from 'react'
import useGetText from '@/hooks/useGetText'
import {
  FulfillmentStatus,
  Device,
  Printer,
  PCs,
  cancelTypes,
  cancelRedText
} from '@/types/OrderList'
import ShipmentStatusStepper from '@/components/OrderHistory/ShipmentStatusStepper'
import { OrderHelper } from '@/helpers/transformOrderResponseHelper'
import { formatProductNumber } from '@/utils/formatProductNumber'
import { StyledErrorMessage, StyledItem } from './styles'
import { GetProductsBySkuQuery } from '@/__generated__/graphql'
import { getStatusByFulfillmentStatus } from '@/components/OrderHistory/ShipmentStatusStepper/Stepper'
import Images from '@/assets/images'

export default function Item({
  item,
  orderFulfillmentData,
  productInfo,
  hasCancellation
}: {
  item: Device
  orderFulfillmentData: FulfillmentStatus
  productInfo: GetProductsBySkuQuery
  hasCancellation?: boolean
}) {
  const [fulfillmentInfo, setFulfillmentInfo] = useState<{
    delivered: boolean
    status: string
    date: string
    statusMapped: string
  }>(null)

  const errorText = useGetText('application.informationMessages')
  const productText = useGetText('application.orderList.orderDetail')
  const cancelText = useGetText('application.notification')
  const getOrderStatusText = useGetText('application.orderStatus')

  const loadingBars = (count: number) => (
    <div data-testid="loading">
      <Skeleton count={count} />
    </div>
  )
  const showSpinner = () => {
    return (
      orderFulfillmentData.loading &&
      !orderFulfillmentData.error &&
      loadingBars(1)
    )
  }
  const showErrorMsg = () => {
    return (
      orderFulfillmentData.error &&
      !orderFulfillmentData.loading && (
        <StyledErrorMessage>
          <IconWarning
            data-testid="fulfillmentError"
            size={18}
            style={{ fill: '#b55500' }}
          />
          {errorText('shipmentStatus.message')}
        </StyledErrorMessage>
      )
    )
  }

  const showShipmentStatus = () => {
    return (
      fulfillmentInfo?.status && (
        <ShipmentStatusStepper
          status={fulfillmentInfo.status}
          delivered={fulfillmentInfo.delivered}
        />
      )
    )
  }

  const fulfillmentStatusInfo = () => {
    return (
      <>
        {showSpinner()}

        {showErrorMsg()}

        {showShipmentStatus()}
      </>
    )
  }

  const displayOrderStatus = (status: string) => {
    const getStatus = getOrderStatusText(status) || status
    return (
      <span>
        {getStatus ? (status === 'error' ? getStatus : `${getStatus}:`) : ''}
      </span>
    )
  }

  let itemFulfillInfo
  if (item && orderFulfillmentData.fulFillments) {
    const fulfill = OrderHelper.getFulfillmentObjById(
      orderFulfillmentData.fulFillments,
      item.fulfillmentId
    )

    itemFulfillInfo = fulfill?.orderItems[item.sku]

    if (fulfillmentInfo == null) {
      setFulfillmentInfo({
        delivered: itemFulfillInfo.delivered,
        status:
          itemFulfillInfo?.delivered === false
            ? itemFulfillInfo?.status
            : 'delivered',
        date: itemFulfillInfo?.timeStamp,
        statusMapped: getStatusByFulfillmentStatus(
          itemFulfillInfo?.delivered === false
            ? itemFulfillInfo?.status
            : 'delivered'
        )
      })
    }
  }

  let productDetail
  if (productInfo) {
    productDetail = OrderHelper.getProductFromData(
      productInfo,
      item.sku,
      item.parentProductSku
    )
  }
  const cancelTypesValues: string[] = Object.values(cancelTypes)
  const redText: string[] = Object.values(cancelRedText)
  const sanitizeTranslationForSpecificStatuses = (item: Device) => {
    if (item.state === cancelTypes.inactive) return 'cancelled.header'
    if (item.state === cancelTypes.deactivating)
      return hasCancellation ? 'cancelling.header' : 'replacing.header'
    if (item.state === cancelTypes.suspended)
      return hasCancellation ? 'cancelling.header' : 'suspended.header'

    return `${item.state}.header`
  }

  return (
    <>
      {item && (
        <StyledItem>
          <div className="fulfillment-status">
            {cancelTypesValues.includes(item.state) ? (
              <div className="order-status">
                {redText.includes(item.state) ? (
                  <span>
                    <strong>
                      {cancelText(sanitizeTranslationForSpecificStatuses(item))}
                    </strong>
                  </span>
                ) : (
                  <>
                    {
                      <span>
                        {cancelText(
                          sanitizeTranslationForSpecificStatuses(item)
                        )}
                      </span>
                    }
                  </>
                )}
              </div>
            ) : (
              <>
                <div className="order-status">
                  {displayOrderStatus(fulfillmentInfo?.statusMapped)}
                  <span>
                    {fulfillmentInfo?.date &&
                      fulfillmentInfo?.delivered === true &&
                      OrderHelper.getFormatDate(
                        new Date(fulfillmentInfo?.date)
                      )}
                  </span>
                </div>
                <div className="stepper-container">
                  {fulfillmentStatusInfo()}
                </div>
              </>
            )}
          </div>
          <div className="product-info" data-testid="item">
            <div className="product">
              <div className="product-image">
                <img
                  alt={productDetail.hp_short_config_name}
                  src={productDetail?.url}
                  tabIndex={0}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null
                    currentTarget.src = Images.printerPlaceholder
                  }}
                />
              </div>
              <div className="product-desc">
                <div className="product-name">
                  {productDetail?.hp_short_config_name}
                </div>
                {item.sku && (
                  <span data-testid="productNumber">
                    <strong>{productText('productNumber')} </strong>
                    {formatProductNumber(item.sku)}
                  </span>
                )}
                {itemFulfillInfo?.serialNumber && (
                  <span data-testid="serialNumber">
                    <strong>{productText('serialNumber')} </strong>
                    {itemFulfillInfo?.serialNumber}
                  </span>
                )}
                {(item as Printer).hasPrintPlan &&
                  (item as Printer).pagesPerMonth > 0 && (
                    <span data-testid="pagePerMonth">
                      <strong>{productText('printPlan')}</strong>
                      {(item as Printer).pagesPerMonth + ' '}
                      {productText('pagePerMonth')}
                    </span>
                  )}

                {(item as PCs).hasCloudStorage && (
                  <span data-testid="storage">
                    <strong>{productText('storagePlan')} </strong>
                    {productText('storagePlanDetail', {
                      gb: (item as PCs).cloudStorage?.toString() || '0',
                      unit: (item as PCs).cloudStorageUnitLabel || 'GB'
                    })}
                  </span>
                )}

                {(item as PCs).has5G && (
                  <span>
                    <strong>{productText('5GConnectivity')} </strong>
                    {productText('5GConnectivityDetail')}
                  </span>
                )}
              </div>
            </div>
          </div>
        </StyledItem>
      )}
    </>
  )
}
