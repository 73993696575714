import getLocation, { getLocaleFromLocation } from './getLocation'
import {
  GetCustomerOrderByIdQuery,
  GetProductsBySkuQuery
} from '@/__generated__/graphql'
import {
  Device,
  Fulfillment,
  Order,
  OrderResponse,
  Parts,
  PCs,
  Printer,
  StorageType,
  cloudStorages,
  PaymentMethodInfo
} from '../types/OrderList'

export const OrderHelper = {
  getOrderObj: (response: OrderResponse[]): Order[] => {
    const orders: Order[] = []
    response.forEach((order) => {
      const orderObj: Order = {}

      orderObj.devices = []
      // setting products
      orderObj.orderId = order?.entities?.find(
        (e) => e.entityType.toLowerCase() === 'iot-printer'
      )?.source?.value?.orderId
      orderObj.orderPlacedAt = order?.entities?.find(
        (e) => e.entityType.toLowerCase() === 'iot-printer'
      )?.entityStartDate

      orderObj.tenantId = order.tenantId
      orderObj.subscriptionId = order.subscriptionId
      orderObj.productSKUs = []
      orderObj.printers = []
      orderObj.pcs = []
      // creating printer detail
      OrderHelper.addPrintersToOrder(order, orderObj)
      // creating pc product detail
      OrderHelper.addPcsToOrder(order, orderObj)
      // creating other physical devices
      OrderHelper.addToDevices(order, orderObj)

      // instantiating objects
      orderObj.deliveryDetail = {}
      orderObj.paymentDetail = {}

      orders.push(orderObj)
    })
    return orders
  },

  addPrintersToOrder: (order: OrderResponse, orderObj: Order) => {
    // creating printer product detail

    order.entities
      .filter((entity) => entity.entityType.toLowerCase() == 'iot-printer')
      .forEach((entity) => {
        const printer: Partial<Printer> = {}

        printer.entityId = entity.entityId
        printer.entityType = entity.entityType.toLowerCase()
        printer.state = entity.state
        printer.sku = entity.product.value.productSku
        printer.parentProductSku = entity.product.value.parentProductSku
        printer.productNumber = '689X3DRTY'
        printer.name = 'HP Envy Inspire all-in-one printer'
        printer.imageUrl = 'not find yet'
        printer.fulfillmentId = entity?.fulfillment?.value?.fulfillmentOrderId
        printer.tenantId = order.tenantId
        printer.subscriptionId = order.subscriptionId
        //commerce subscription id
        printer.commerceSubscriptionId =
          entity.commerce?.value?.subscriptionId || undefined

        entity.linkedEntities.forEach((linkedEntityObj) => {
          const linkedEntityId = linkedEntityObj.value
          // finding linked ids and getting details
          const linkedEntity = order.entities.find(
            (item) => item.entityId == linkedEntityId
          )

          // checking if linked id has instant ink
          if (linkedEntity) {
            const entityType = linkedEntity.entityType.toLowerCase()
            if (printer.hasPrintPlan != true) {
              printer.hasPrintPlan = entityType == 'instant-ink' ? true : false
              printer.instantInkSKU = linkedEntity.product.value.productSku
            }
          }
        })

        orderObj.productSKUs.push(printer.parentProductSku)
        orderObj.printers.push(printer)
      })
  },

  addPcsToOrder: (order: OrderResponse, orderObj: Order) => {
    order.entities
      .filter(
        (entity) =>
          entity.entityType.toLowerCase() == 'iot-pc' ||
          entity.entityType.toLowerCase() == 'chromebook'
      )
      .forEach((entity) => {
        const pc: Partial<PCs> = {}
        pc.entityId = entity.entityId
        pc.entityType = entity.entityType.toLowerCase()
        pc.state = entity.state
        pc.fulfillmentId = entity?.fulfillment?.value?.fulfillmentOrderId
        pc.tenantId = order.tenantId
        pc.subscriptionId = order.subscriptionId
        pc.name = 'ZBook G9'
        pc.sku = entity.product.value.productSku
        pc.parentProductSku = entity.product.value.parentProductSku
        pc.imageUrl = 'not find yet'
        pc.commerceSubscriptionId =
          entity.commerce?.value?.subscriptionId || undefined

        entity.linkedEntities.forEach((linkedEntityObj) => {
          const linkedEntityId = linkedEntityObj.value
          // finding linked ids and getting details
          const linkedEntity = order.entities.find(
            (item) => item.entityId == linkedEntityId
          )
          // checking if linked id has instant ink
          if (linkedEntity) {
            const entityType = linkedEntity.entityType.toLowerCase()
            const productType =
              linkedEntity.product.value.productType.toLowerCase()

            if (pc.hasCloudStorage != true) {
              pc.storageType = productType as StorageType
              pc.hasCloudStorage =
                entityType == 'third-party-software' &&
                cloudStorages.includes(pc.storageType)
                  ? true
                  : false
              pc.cloudStorageSKU = linkedEntity.product.value.productSku
            }

            if (pc.has5G != true) {
              pc.has5G =
                entityType == 'third-party-software' &&
                productType == 'connectivity'
                  ? true
                  : false
            }
          }
        })

        orderObj.productSKUs.push(pc.parentProductSku)
        orderObj.pcs.push(pc)
      })
  },

  addToDevices: (order: OrderResponse, orderObj: Order) => {
    order.entities.forEach((entity) => {
      if (entity.fulfillment?.type == 'sfs') {
        const entityType = entity.entityType.toLowerCase()
        if (entityType == 'iot-printer') {
          orderObj.devices.push(
            orderObj.printers.find(
              (printer) => printer.entityId == entity.entityId
            )
          )
        } else if (
          entity.entityType.toLowerCase() == 'iot-pc' ||
          entity.entityType.toLowerCase() == 'chromebook'
        ) {
          orderObj.devices.push(
            orderObj.pcs.find((pcs) => pcs.entityId == entity.entityId)
          )
        } else {
          const device: Device = {}
          device.sku = entity.product.value.productSku
          device.parentProductSku = entity.product.value.parentProductSku
          device.fulfillmentId = entity?.fulfillment?.value?.fulfillmentOrderId
          device.tenantId = order.tenantId
          device.subscriptionId = order.subscriptionId
          device.state = order.state

          if (orderObj.productSKUs.includes(device.parentProductSku) == false) {
            orderObj.productSKUs.push(device.parentProductSku)
          }
          orderObj.devices.push(device)
        }
      }
    })
  },

  getFulfillmentObj: (fulfillments: Fulfillment[]): Fulfillment[] => {
    fulfillments = fulfillments.map((item) => {
      const fulfillmentItem = {
        fulfillmentOrderId: item.fulfillmentOrderId,
        orderItems: {}
      }
      const orderItems: { [key in string]: Parts } = {}
      item.parts?.forEach((part) => (orderItems[part.modelSku] = part))

      fulfillmentItem.orderItems = orderItems
      return fulfillmentItem
    })
    return fulfillments
  },

  getFulfillmentObjById: (
    fulfillments: Fulfillment[],
    fulfillmentOrderId: string
  ): Fulfillment => {
    fulfillments = fulfillments?.filter(
      (item) => item.fulfillmentOrderId == fulfillmentOrderId
    )
    return fulfillments?.length > 0 ? fulfillments[0] : null
  },

  getFulfillmentByProductSku: (
    fulfillments: Fulfillment[],
    productSku: string
  ): Parts => {
    fulfillments = fulfillments?.filter(
      (item) => item.orderItems[productSku].modelSku == productSku
    )
    return fulfillments[0]?.orderItems[productSku]
  },

  getProductFromData: (
    data: GetProductsBySkuQuery,
    sku: string,
    parentProductSku: string
  ) => {
    const result: { hp_short_config_name: string; url: string } = {
      hp_short_config_name: '',
      url: ''
    }

    const parentProduct: any = data?.products?.items?.find(
      (item) => item.sku == parentProductSku
    )

    if (parentProduct?.items?.length > 0) {
      parentProduct?.items?.find((it) => {
        const item = it?.options?.find((subit) => subit?.product?.sku == sku)
        if (item) {
          result.hp_short_config_name = item.product.hp_short_config_name
          if (item.product?.thumbnail?.url) {
            result.url = item.product?.thumbnail?.url
          } else {
            result.url = item.product.image?.url
          }
        }
      })
    }

    return result
  },

  updateOrderObjCustomerData: (
    data: GetCustomerOrderByIdQuery,
    order: Order
  ) => {
    const orderDetail = data.customer.orders.items[0]
    if (orderDetail) {
      order.deliveryDetail.name = `${data.customer.firstname} ${data.customer.lastname}`
      order.deliveryDetail.addressLine1 = orderDetail.shipping_address.street[0]
      order.deliveryDetail.addressLine2 = `${orderDetail.shipping_address.city}, ${orderDetail.shipping_address.region}  ${orderDetail.shipping_address.postcode}`

      order.paymentDetail.name = `${data.customer.firstname} ${data.customer.lastname}`
      order.orderTotal = orderDetail.total.grand_total.value
      order.orderCurrency = orderDetail.total.grand_total.currency
      order.orderDeviceTotal = orderDetail.total?.device_total_with_tax?.value
      order.orderDeviceCurrency =
        orderDetail.total?.device_total_with_tax?.currency
      order.orderNumber = `${orderDetail?.number}`
    }
  },

  updateProductData: (data: GetProductsBySkuQuery, order: Order) => {
    order?.devices?.forEach((device: Device) => {
      const instantInkSku = (device as Printer).instantInkSKU
      if (instantInkSku) {
        const printer = data.products.items.find(
          (item) => item.sku == device.parentProductSku
        )
        if (printer) {
          // printer.items are there but editor can find it
          const printerItems: any[] = (printer as any).items

          ;(device as Printer).pagesPerMonth = 0

          printerItems.forEach((pItem) => {
            const instantInkSubscription = pItem.options.find(
              (it) => it.product.sku == instantInkSku
            )
            if (instantInkSubscription) {
              ;(device as Printer).pagesPerMonth =
                instantInkSubscription.product.hp_pages_per_month
            }
          })
        }
      }

      const cloudStorageSku = (device as PCs).cloudStorageSKU
      if (cloudStorageSku) {
        const pc = data.products.items.find(
          (item) => item.sku == device.parentProductSku
        )
        // printer.items are there but editor can find it
        const pcItem: any[] = (pc as any).items

        pcItem.forEach((pItem) => {
          const cloudStorageSubscription = pItem.options.find(
            (it) => it.product.sku == cloudStorageSku
          )
          if (cloudStorageSubscription) {
            ;(device as PCs).cloudStorage =
              cloudStorageSubscription.product.hp_storage_amount
            ;(device as PCs).cloudStorageUnitLabel =
              cloudStorageSubscription.product.hp_storage_uom_label
          }
        })
      }
    })
  },

  updatePaymentDetails: (
    paymentInfo: Partial<PaymentMethodInfo>,
    order: Order
  ) => {
    if (order && paymentInfo) {
      order.paymentDetail.paymentMethod = paymentInfo || undefined
    }
  },

  getFormatDate(date: Date) {
    // internal helper function for MVP en-US iOS formatting
    const iOSDateFormatter = (date): string => {
      const dateArr = date.toString().split(' ')
      return `${dateArr[1]} ${dateArr[2]}, ${dateArr[3]}`
    }

    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    }

    const dateToDisplay = /iPad|iPhone|iPod/.test(navigator.userAgent)
      ? iOSDateFormatter(date)
      : date.toLocaleString(getLocaleFromLocation(getLocation()), options)

    return dateToDisplay
  }
}
